export const AUTHENTICATE = "AUTHENTICATE";
export const AUTH_ERROR = "AUTH_ERROR";
export const SIGNOUT = "SIGNOUT";

export const UPDATE_PASSWORD_FULFILLED = "UPDATE_PASSWORD_FULFILLED";
export const UPDATE_PASSWORD_REJECTED = "UPDATE_PASSWORD_REJECTED";

export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_REJECTED = "RESET_PASSWORD_REJECTED";

export const INCREASE_ON_BOARDING_PROCESS_STEP_FULFILLED =
  "INCREASE_ON_BOARDING_PROCESS_STEP_FULFILLED";
export const INCREASE_ON_BOARDING_PROCESS_STEP_REJECTED =
  "INCREASE_ON_BOARDING_PROCESS_STEP_REJECTED";

export const DECREASE_ON_BOARDING_PROCESS_STEP_FULFILLED =
  "DECREASE_ON_BOARDING_PROCESS_STEP_FULFILLED";
export const DECREASE_ON_BOARDING_PROCESS_STEP_REJECTED =
  "DECREASE_ON_BOARDING_PROCESS_STEP_REJECTED";

export const COMPLETE_ON_BOARDING_PROCESS_FULFILLED =
  "COMPLETE_ON_BOARDING_PROCESS_FULFILLED";
export const COMPLETE_ON_BOARDING_PROCESS_REJECTED =
  "COMPLETE_ON_BOARDING_PROCESS_REJECTED";

export const SAVE_REDIRECT_PARAMETER_FULFILLED =
  "SAVE_REDIRECT_PARAMETER_FULFILLED";

export const FLIGHT_STARTED = "FLIGHT_STARTED";
export const FLIGHT_ARRIVED = "FLIGHT_ARRIVED";

export const DASHBOARD_GET_LIST_USER_COURSES_FULFILLED =
  "DASHBOARD_GET_LIST_USER_COURSES_FULFILLED";
export const DASHBOARD_GET_LIST_USER_COURSES_REJECTED =
  "DASHBOARD_GET_LIST_USER_COURSES_REJECTED";

export const DASHBOARD_GET_MODULE_FULFILLED = "DASHBOARD_GET_MODULE_FULFILLED";
export const DASHBOARD_GET_MODULE_REJECTED = "DASHBOARD_GET_MODULE_REJECTED";

export const DASHBOARD_GET_MODULE_COURSE_FULFILLED =
  "DASHBOARD_GET_MODULE_COURSE_FULFILLED";
export const DASHBOARD_GET_MODULE_COURSE_REJECTED =
  "DASHBOARD_GET_MODULE_COURSE_REJECTED";

export const DASHBOARD_UPDATE_FEEDBACK_SCORE =
  "DASHBOARD_UPDATE_FEEDBACK_SCORE";
export const DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION =
  "DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION";

export const DASHBOARD_ADD_FEEDBACK = "DASHBOARD_ADD_FEEDBACK";
export const DASHBOARD_ADD_FEEDBACK_REJECTED =
  "DASHBOARD_ADD_FEEDBACK_REJECTED";

export const DASHBOARD_DISMISS_FEEDBACK = "DASHBOARD_DISMISS_FEEDBACK";
export const DASHBOARD_AVAILABLE_INSTITUTE_MODULE_FULFILLED="DASHBOARD_AVAILABLE_INSTITUTE_MODULE_FULFILLED";
export const DASHBOARD_AVAILABLE_INSTITUTE_MODULE_REJECTED="DASHBOARD_AVAILABLE_INSTITUTE_MODULE_REJECTED";
export const DASHBOARD_ASSIGNED_INSTITUTE_MODULE_FULFILLED="DASHBOARD_ASSIGNED_INSTITUTE_MODULE_FULFILLED";
export const DASHBOARD_ASSIGNED_INSTITUTE_MODULE_REJECTED ="DASHBOARD_ASSIGNED_INSTITUTE_MODULE_REJECTED";

//Landing Page Constants
export const LANDING_GET_FEATURED_COURSES_FULFILLED =
  "LANDING_GET_FEATURED_COURSES_FULFILLED";
export const LANDING_GET_FEATURED_COURSES_REJECTED =
  "LANDING_GET_FEATURED_COURSES_REJECTED";

export const LANDING_GET_ALL_PRODUCTS_FULFILLED =
  "LANDING_GET_ALL_PRODUCTS_FULFILLED";
export const LANDING_GET_ALL_PRODUCTS_REJECTED =
  "LANDING_GET_ALL_PRODUCTS_REJECTED";

export const LANDING_GET_COURSE_BY_ID_FULFILLED =
  "LANDING_GET_COURSE_BY_ID_FULFILLED";
export const LANDING_GET_COURSE_BY_ID_REJECTED =
  "LANDING_GET_COURSE_BY_ID_REJECTED";

export const LANDING_GET_COURSES_RELATED_FULFILLED =
  "LANDING_GET_COURSES_RELATED_FULFILLED";

export const LANDING_GET_COURSES_RELATED_REJECTED =
  "LANDING_GET_COURSES_RELATED_REJECTED";

export const LANDING_GET_COURSES_BY_CATEGORY_FULFILLED =
  "LANDING_GET_COURSES_BY_CATEGORY_FULFILLED";
export const LANDING_GET_COURSES_BY_CATEGORY_REJECTED =
  "LANDING_GET_COURSES_BY_CATEGORY_REJECTED";
export const LANDING_GET_PRODUCT_BY_ID_FULFILLED =
  "LANDING_GET_PRODUCT_BY_ID_FULFILLED";
export const LANDING_SET_PRODUCTS_LOADING = "LANDING_SET_PRODUCTS_LOADING";
export const LANDING_SET_COURSES_LOADING = "LANDING_SET_PRODUCTS_LOADING";
export const LANDING_GET_PRODUCT_BY_ID_REJECTED =
  "LANDING_GET_PRODUCT_BY_ID_REJECTED";

export const LANDING_GET_VIDEOS_BY_MODULEID_FULFILLED =
  "LANDING_GET_VIDEOS_BY_MODULEID_FULFILLED";
export const LANDING_GET_VIDEOS_BY_MODULEID_REJECTED =
  "LANDING_GET_VIDEOS_BY_MODULEID_REJECTED";

//Groups
export const LANDING_SET_GROUPS_LOADING = "LANDING_SET_GROUPS_LOADING";
export const LANDING_GET_ALL_GROUPS_FULFILLED =
  "LANDING_GET_ALL_GROUPS_FULFILLED";
export const LANDING_GET_ALL_GROUPS_REJECTED =
  "LANDING_GET_ALL_GROUPS_REJECTED";

export const LANDING_GET_SOFTWARE_LIST_FULFILLED = "LANDING_GET_SOFTWARE_LIST";
export const LANDING_GET_SOFTWARE_LIST_REJECTED =
  "LANDING_GET_SOFTWARE_LIST_REJECTED";
export const LANDING_SEND_REQUEST_DEMO_EMAIL_FULFILLED =
  "LANDING_SEND_REQUEST_DEMO_EMAIL_FULFILLED";
export const LANDING_SEND_REQUEST_DEMO_EMAIL_REJECTED =
  "LANDING_SEND_REQUEST_DEMO_EMAIL_REJECTED";

export const SWITCH_LANGUAGE_FULFILLED = "SWITCH_LANGUAGE_FULFILLED";

//Quiz Services Actions
export const QUIZ_CREATE_QUIZ = "QUIZ_CREATE_QUIZ";
export const QUIZ_RETRIEVE_TIME_REMAINING = "QUIZ_RETRIEVE_TIME_REMAINING";
export const QUIZ_RETRIEVE_QUIZ_BY_EMPLOYEE_QUIZ_ID =
  "QUIZ_RETRIEVE_QUIZ_BY_EMPLOYEE_QUIZ_ID";
export const QUIZ_COMPLETE_QUIZ_BY_QUIZ_ID = "QUIZ_COMPLETE_QUIZ_BY_QUIZ_ID";
export const QUIZ_ANSWER_QUESTIONS_BY_QUIZ = "QUIZ_ANSWER_QUESTIONS_BY_QUIZ";
export const QUIZ_ANSWER_QUESTIONS = "QUIZ_ANSWER_QUESTIONS";
export const QUIZ_COMPLETE_QUIZ_BY_ID = "QUIZ_COMPLETE_QUIZ_BY_ID";
export const COURSE_SET_COMPLETED = "COURSE_SET_COMPLETED";
export const COURSE_SET_COMPLETED_REJECTED = "COURSE_SET_COMPLETED_REJECTED";
export const LANDING_GET_ALL_MODULES_FULFILLED =
  "LANDING_GET_ALL_MODULES_FULFILLED";

export const RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_FULFILLED =
  "RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_FULFILLED";
export const RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_EJECTED =
  "RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_EJECTED";

/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
export const REQUEST_DEPARTED = "REQUEST_DEPARTED";
export const REQUEST_ARRIVED = "REQUEST_ARRIVED";

export const GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_FULFILLED =
  "GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_FULFILLED";
export const GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_REJECTED =
  "GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_REJECTED";

export const ADD_USER_WITH_SUBSCRIPTION_PLAN_FULFILLED =
  "ADD_USER_WITH_SUBSCRIPTION_PLAN_FULFILLED";
export const ADD_USER_WITH_SUBSCRIPTION_PLAN_REJECTED =
  "ADD_USER_WITH_SUBSCRIPTION_PLAN_REJECTED";

export const GET_PRODUCT_PRICES_FULFILLED = "GET_PRODUCT_PRICES_FULFILLED";
export const GET_PRODUCT_PRICES_REJECTED = "GET_PRODUCT_PRICES_REJECTED";
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/

export const GET_ALL_PRODUCTS_FULFILLED = "GET_ALL_PRODUCTS_FULFILLED";
export const GET_ALL_PRODUCTS_REJECTED = "GET_ALL_PRODUCTS_REJECTED";

export const GET_PRODUCTS_BY_TYPE_FULFILLED = "GET_PRODUCTS_BY_TYPE_FULFILLED";
export const GET_PRODUCTS_BY_TYPE_REJECTED = "GET_PRODUCTS_BY_TYPE_REJECTED";

export const GET_PRODUCT_BY_ID_FULFILLED = "GET_PRODUCT_BY_ID_FULFILLED";
export const GET_PRODUCT_BY_ID_REJECTED = "GET_PRODUCT_BY_ID_REJECTED";

export const GET_ORDER_BY_ID_FULFILLED = "GET_ORDER_BY_ID_FULFILLED";
export const GET_ORDER_BY_ID_REJECTED = "GET_ORDER_BY_ID_REJECTED";

export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";

export const ADD_SELECTED_PRODUCT_TO_CART = "ADD_SELECTED_PRODUCT_TO_CART";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART";

export const CREATE_USER_FULFILLED = "CREATE_USER_FULFILLED";
export const CREATE_USER_REJECTED = "CREATE_USER_REJECTED";

export const PLACE_ORDER_FULFILLED = "PLACE_ORDER_FULFILLED";
export const PLACE_ORDER_REJECTED = "PLACE_ORDER_REJECTED";

export const GET_SHIPPING_FEE_FULFILLED = "GET_SHIPPING_FEE_FULFILLED";
export const GET_SHIPPING_FEE_REJECTED = "GET_SHIPPING_FEE_REJECTED";
export const RESET_SHIPPING_FEE = "RESET_SHIPPING_FEE";

export const GET_TAX_AMOUNT_FULFILLED = "GET_TAX_AMOUNT_FULFILLED";
export const GET_TAX_AMOUNT_REJECTED = "GET_TAX_AMOUNT_REJECTED";

export const SUBMITTING_ORDER_FLAG = "SUBMITTING_ORDER_FLAG";
export const PURCHASE_SUCCESSFUL_FLAG = "PURCHASE_SUCCESSFUL_FLAG";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_BACKEND_ERROR = "CLEAR_BACKEND_ERROR";

export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";
export const CLEAR_SIGN_IN_ERROR = "CLEAR_SIGN_IN_ERROR";
export const CLEAR_SIGN_UP_ERROR = "CLEAR_SIGN_UP_ERROR";
export const CLEAR_SIGN_UP_CONFIRMATION = "CLEAR_SIGN_UP_CONFIRMATION";

export const GET_VIEWS_BY_USER_FULFILLED = "GET_VIEWS_BY_USER_FULFILLED";
export const GET_VIEWS_BY_USER_REJECTED = "GET_VIEWS_BY_USER_REJECTED";

export const INCREASE_VIDEO_VIEWS_FULFILLED = "INCREASE_VIDEO_VIEWS_FULFILLED";
export const INCREASE_VIDEO_VIEWS_REJECTED = "INCREASE_VIDEO_VIEWS_REJECTED";

export const SAVE_MEDIA_PROPERTIES = "SAVE_MEDIA_PROPERTIES";
export const CLEAR_MEDIA_PROPERTIES = "CLEAR_MEDIA_PROPERTIES";

export const SAVE_READING_BOOKMARKS = "SAVE_READING_BOOKMARKS";
export const SAVE_READING_HIGHLIGHTS = "SAVE_READING_HIGHLIGHTS";
export const SAVE_READING_CONFIGURATION = "SAVE_READING_CONFIGURATION";
export const SAVE_READING_LAST_LOCATION = "SAVE_READING_LAST_LOCATION";

export const GET_READING_BOOKMARKS = "GET_READING_BOOKMARKS";
export const GET_READING_HIGHLIGHTS = "GET_READING_HIGHLIGHTS";
export const GET_READING_CONFIGURATION = "GET_READING_CONFIGURATION";
export const READING_SEND_HIGHLIGHTS_EMAIL = "READING_SEND_HIGHLIGHTS_EMAIL";
export const READING_SEND_HIGHLIGHTS_EMAIL_OFF =
  "READING_SEND_HIGHLIGHTS_EMAIL_OFF";

export const SET_IS_FIRST_SUBSCRIPTION_PLAN_RENDER =
  "SET_IS_FIRST_SUBSCRIPTION_PLAN_RENDER";
export const AUTHENTICATION_CERTIFICATES_GET_BY_ID =
  "AUTHENTICATION_CERTIFICATES_GET_BY_ID";

export const DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED =
  "DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED";
export const DASHBOARD_GET_USER_NOT_JOINED_COURSES_REJECTED =
  "DASHBOARD_GET_USER_NOT_JOINED_COURSES_REJECTED";
export const DASHBOARD_GET_USER_WISHLIST_FULFILLED =
  "DASHBOARD_GET_USER_WISHLIST_FULFILLED";
export const DASHBOARD_ADD_USER_WISHLIST_FULFILLED =
  "DASHBOARD_ADD_USER_WISHLIST_FULFILLED";
export const DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED =
  "DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED";
export const DASHBOARD_GET_USER_WISHLIST_REJECTED =
  "DASHBOARD_GET_USER_WISHLIST_REJECTED";
export const DASHBOARD_ADD_USER_WISHLIST_REJECTED =
  "DASHBOARD_ADD_USER_WISHLIST_REJECTED";
export const DASHBOARD_GET_LEADERS_BY_ORGANIZATION_FULFILLED="DASHBOARD_GET_LEADERS_BY_ORGANIZATION_FULFILLED"  ;
export const DASHBOARD_GET_LEADERS_BY_ORGANIZATION_REJECTED="DASHBOARD_GET_LEADERS_BY_ORGANIZATION_REJECTED"
export const DASHBOARD_GET_ORGANIZATION_USER_JOINED_FULFILLED="DASHBOARD_GET_ORGANIZATION_USER_JOINED_FULFILLED";
export const DASHBOARD_GET_ORGANIZATION_USER_JOINED_REJECTED="DASHBOARD_GET_ORGANIZATION_USER_JOINED_REJECTED";

export const DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_FULFILLED="DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_FULFILLED";
export const DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_REJECTED="DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_REJECTED";

export const DASHBOARD_ASSOCIATES_BY_LEADER_ID_FULFILLED="DASHBOARD_ASSOCIATES_BY_LEADER_ID_FULFILLED";
export const DASHBOARD_ASSOCIATES_BY_LEADER_ID_REJECTED="DASHBOARD_ASSOCIATES_BY_LEADER_ID_REJECTED";

export const AUTHENTICATION_CERTIFICATES_END_DOWNLOAD_CERTIFICATE =
  "AUTHENTICATION_CERTIFICATES_END_DOWNLOAD_CERTIFICATE";
export const AUTHENTICATION_CERTIFICATES_ON_CERTIFICATE_DOWNLOAD =
  "AUTHENTICATION_CERTIFICATES_ON_CERTIFICATE_DOWNLOAD";

export const DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID="DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID";
export const DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID_REJECTED="DASHBOARD_GET_INSTITUTE_ROLE_BY_EMPLOYEE_ID_REJECTED";
export const DASHBOARD_UPSERT_AVAILABLE_COURSES_FULFILLED="DASHBOARD_UPSERT_AVAILABLE_COURSES_FULFILLED";
export const DASHBOARD_UPSERT_AVAILABLE_COURSES_REJECTED="DASHBOARD_UPSERT_AVAILABLE_COURSES_REJECTED";
/*----------------------------------------------------------------------------*/
//Reset Token
/*----------------------------------------------------------------------------*/
export const RESET_PASSWORD_VALIDATDION = "RESET_PASSWORD_VALIDATDION";
export const RESET_PASSWORD_VALIDATDION_ERROR =
  "RESET_PASSWORD_VALIDATDION_ERROR";
export const RESET_PASSWORD_UPDATE = "RESET_PASSWORD_UPDATE";
export const RESET_PASSWORD_UPDATE_ERROR = "RESET_PASSWORD_UPDATE_ERROR";
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------*/
//Employee Licenses
/*----------------------------------------------------------------------------*/
export const RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_FULFILLED =
  "RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_FULFILLED";
export const RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_ERROR =
  "RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_ERROR";

export const ENROLL_EMPLOYEELINCENSE_FULFILLED =
  "ENROLL_EMPLOYEELINCENSE_FULFILLED";
export const ENROLL_EMPLOYEELINCENSE_ERROR = "RETRIEVE_EMPLOYEELINCENSE_ERROR";

export const ACTIVATE_EMPLOYEE_ERROR = "ACTIVATE_EMPLOYEE_ERROR";
export const ACTIVATE_EMPLOYEE_FULFILLED = "ACTIVATE_EMPLOYEE_FULFILLED";
export const DELETE_LICENSE_EMPLOYEE_ERROR = "DELETE_LICENSE_EMPLOYEE_ERROR";
export const DELETE_LICENSE_FULFILLED = "DELETE_LICENSE_EMPLOYEE_FULFILLED";
export const INCREMENT_CART_ITEMS = "INCREMENT_CART_ITEMS";
export const DECREMENT_CART_ITEMS = "INCREMENT_CART_ITEMS";

export const LANDING_GET_USER_MODULE_FRONT_END_FULFILLED="LANDING_GET_USER_MODULE_FRONT_END_FULFILLED";
export const LANDING_GET_USER_MODULE_FRONT_END_REJECTED="LANDING_GET_USER_MODULE_FRONT_END_REJECTED";

export const DASHBOARD_GET_PROGRESS_COURSES_FULFILLED="DASHBOARD_GET_PROGRESS_COURSES_FULFILLED";

export const DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_FULFILLED="DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_FULFILLED";
export const DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_REJECTED="DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_REJECTED";

export const DASHBOARD_GET_VERIFY_USERMAIL_EXIST_FULFILLED="DASHBOARD_GET_VERIFY_USERMAIL_EXIST_FULFILLED";
export const DASHBOARD_GET_VERIFY_USERMAIL_EXIST_REJECTED="DASHBOARD_GET_VERIFY_USERMAIL_EXIST_REJECTED";

export const DASHBOARD_GET_NEW_USER_INVITATIONS_FULFILLED="DASHBOARD_GET_NEW_USER_INVITATIONS_FULFILLED";
export const DASHBOARD_GET_NEW_USER_INVITATIONS_REJECTED="DASHBOARD_GET_NEW_USER_INVITATIONS_REJECTED";

export const DASHBOARD_DELETE_PENDING_INVITATION_FULFILLED="DASHBOARD_DELETE_PENDING_INVITATION_FULFILLED";
export const DASHBOARD_DELETE_PENDING_INVITATION_REJECTED="DASHBOARD_DELETE_PENDING_INVITATION_REJECTED";

export const DASHBOARD_SEND_NEW_USER_INVITATION_FULFILLED="DASHBOARD_SEND_NEW_USER_INVITATION_FULFILLED";
export const DASHBOARD_SEND_NEW_USER_INVITATION_REJECTED="DASHBOARD_SEND_NEW_USER_INVITATION_REJECTED";

export const DASHBOARD_GET_PENDING_INVITATION_BY_ID_FULFILLED = "DASHBOARD_GET_PENDING_INVITATION_BY_ID_FULFILLED";
export const DASHBOARD_GET_PENDING_INVITATION_BY_ID_REJECTED = "DASHBOARD_GET_PENDING_INVITATION_BY_ID_REJECTED";

export const DASHBOARD_ACCEPT_INVITATION_FULFILLED="DASHBOARD_ACCEPT_INVITATION_FULFILLED";
export const DASHBOARD_ACCEPT_INVITATION_REJECTED="DASHBOARD_ACCEPT_INVITATION_REJECTED";

export const DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_FULFILLED="DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_FULFILLED";
export const DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_REJECTED="DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_REJECTED";
export const DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_FULFILLED="DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_FULFILLED";
export const DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_REJECTED="DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_REJECTED";
export const DASHBOARD__UPSERAT_USER_ORGANIZATIONS_FULFILLED="DASHBOARD__UPSERAT_USER_ORGANIZATIONS_FULFILLED";
export const DASHBOARD__UPSERAT_USER_ORGANIZATIONS_REJECTED="DASHBOARD__UPSERAT_USER_ORGANIZATIONS_REJECTED";