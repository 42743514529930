import * as types from "../constants/actionTypes";
import AuthenticationServicesInstance from "../services/authenticationServices";
import WishlistServices from "../services/wishlistServices";
import OrganizationServices from "../services/organizationService"
import CoursesServices from "../services/coursesServices";
import CourseAssignationServicesInstance from "../services/courseAssignationService";

const authenticationServices = new AuthenticationServicesInstance();
const wishlistServices = new WishlistServices();
const organizationServices = new OrganizationServices();
const coursesServices = new CoursesServices();
const courseAssignationServices = new CourseAssignationServicesInstance();

export function getLisUserCourses(userId, lang) {
  const token = localStorage.getItem("token");

  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getLisUserCourses(userId, token, lang, 0)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserModule(idModule, idEmployee, lang, courseType) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserModule(idModule, idEmployee, token, lang, courseType)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_MODULE_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });

        dispatch({
          type: types.DASHBOARD_GET_MODULE_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserModuleCourse(idCourse, idEmployee, lang) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserCourse(idCourse, idEmployee, token, lang)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_MODULE_COURSE_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_MODULE_COURSE_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function updateFeedbackScore(score) {
  return function(dispatch) {
    dispatch({
      type: types.DASHBOARD_UPDATE_FEEDBACK_SCORE,
      payload: score,
    });
  };
}

export function updateFeedbackDescription(description) {
  return function(dispatch) {
    dispatch({
      type: types.DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION,
      payload: description,
    });
  };
}

export function uploadFeedback(feedback) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .addFeedback(feedback, token)
      .then((response) => {
        dispatch({ type: types.DASHBOARD_ADD_FEEDBACK, payload: response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_ADD_FEEDBACK_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function dismissFeedback() {
  return function(dispatch) {
    dispatch({ type: types.DASHBOARD_DISMISS_FEEDBACK });
  };
}

export function getUserNotJoinedCourses(idEmployee, lang) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserNotJoinedCourses(idEmployee, lang, token)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserWishlist(idEmployee) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .getByEmployeeId(idEmployee)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function addToWishlist(idEmployee, module) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .addToWishlist(idEmployee, module)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_ADD_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function removeToWishlist(idEmployee, module) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .removeToWishlist(idEmployee, module)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getOrganizationByAssigner(idEmployee, idSupportedSystem){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    const token= localStorage.getItem("token");
    organizationServices.getById(idEmployee, idSupportedSystem, token).then((r)=>{
      dispatch({
        type: types.DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}
export function getOrganizationsJoinedByEmployee(email, idSupportedSystem){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    const token= localStorage.getItem("token");
    organizationServices.getOrganizationsJoinedByEmployee(email, idSupportedSystem, token).then((r)=> {
      dispatch({
        type: types.DASHBOARD_GET_ORGANIZATION_USER_JOINED_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_ORGANIZATION_USER_JOINED_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function getLeadersByOrganization(idOrganization){
  return function(dispatch){

    dispatch({ type: types.FLIGHT_STARTED });
    const token = localStorage.getItem("token");

    authenticationServices.getLeadersByOrganization(idOrganization,token).then((r)=>{

      dispatch({
        type: types.DASHBOARD_GET_LEADERS_BY_ORGANIZATION_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{

      dispatch({
        type: types.DASHBOARD_GET_LEADERS_BY_ORGANIZATION_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
}

export function getAssociatesByOrganization(idOrganization){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    const token = localStorage.getItem("token");
    authenticationServices.getAssociatesByOrganization(idOrganization, token).then((r)=>{

      const {data} = r;
      dispatch({
        type: types.DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_FULFILLED,
        payload: data,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{

      dispatch({
        type: types.DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
}

export function getAssociatesByLeader(idLeader, idOrganization){
  return function(dispatch){
    const token = localStorage.getItem("token");
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices.getAssociatesByLeaderAndOrganization(idLeader,idOrganization, token).then((r)=>{

      const {data} = r;
      dispatch({
        type: types.DASHBOARD_ASSOCIATES_BY_LEADER_ID_FULFILLED,
        payload: data,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{

      dispatch({
        type: types.DASHBOARD_ASSOCIATES_BY_LEADER_ID_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
}

export function getAvaibleInstituteModuleByOrganization(idOrganization){

  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices.getAvaibleInstituteModuleByOrganization(idOrganization).then(r=>{
      const {data} = r;
      dispatch({
        type: types.DASHBOARD_AVAILABLE_INSTITUTE_MODULE_FULFILLED,
        payload: data,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    }).catch(e=>{

      dispatch({
        type: types.DASHBOARD_AVAILABLE_INSTITUTE_MODULE_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
}

export function getAssignedInstituteModuleByLeader(idLeader, idAssociate){

  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices.getAssignedInstituteModuleByLeader(idLeader, idAssociate).then(r=>{
      const {data} = r;
      dispatch({
        type: types.DASHBOARD_ASSIGNED_INSTITUTE_MODULE_FULFILLED,
        payload: data,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    }).catch(e=>{

      dispatch({
        type: types.DASHBOARD_ASSIGNED_INSTITUTE_MODULE_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
} 

export function upsertAvailableCourses( idSupportedSystem,  idLeader,  associate,  newCourses, callback){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices.upsertAvailableCourses(idSupportedSystem,  idLeader,  associate,  newCourses).then(r=>{
      const {data} = r;
      dispatch({
        type: types.DASHBOARD_UPSERT_AVAILABLE_COURSES_FULFILLED,
        payload: data,
      });
      callback(true);
      dispatch({ type: types.FLIGHT_ARRIVED });
    }).catch(e=>{
      callback(false);
      dispatch({
        type: types.DASHBOARD_UPSERT_AVAILABLE_COURSES_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    })
  }
}

export function getCoursesProgress(idOrganization, idLeader){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    courseAssignationServices.getCoursesProgress(idOrganization, idLeader).then(r=>{
      dispatch({
        type: types.DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function verifyEmailExist(email, token){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices.verifyEmailExist(email, token).then(r=>{
      dispatch({
        type: types.DASHBOARD_GET_VERIFY_USERMAIL_EXIST_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_VERIFY_USERMAIL_EXIST_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function getNewUserInvitationsBySupportedSystemId(idSupportedSystem){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    courseAssignationServices.getNewUserInvitationsBySupportedSystemId(idSupportedSystem).then(r=>{
      dispatch({
        type: types.DASHBOARD_GET_NEW_USER_INVITATIONS_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_NEW_USER_INVITATIONS_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function getNewUserInvitationsByID(idInvitation){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    courseAssignationServices.getPendingInvitationById(idInvitation).then(r=>{
      dispatch({
        type: types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function deletePendingInvitationAsync(id, callback){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    courseAssignationServices.removePendingInvitation(id).then(r=>{
      dispatch({
        type: types.DASHBOARD_DELETE_PENDING_INVITATION_FULFILLED,
        payload: id,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
      callback(true);
    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_DELETE_PENDING_INVITATION_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
      callback(false);
    })
  }
}

export function sendNewUserInvitation(assignerId, supportedSystem, name, lastName, email, orgs, token, callback){

  const orgsmap = orgs.map(o=>o);

  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices.createUserAssignedInvitation(assignerId, supportedSystem, name, lastName, email, orgsmap, token).then(r=>{
      dispatch({
        type: types.DASHBOARD_SEND_NEW_USER_INVITATION_FULFILLED,
        payload: {firstName:name, lastName, email, orgsmap},
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
      callback(true)
    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_SEND_NEW_USER_INVITATION_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
      callback(false)
    })
  }
}

export function upsertUserOrganizations( supportedSystem, email, orgs, token, callback){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices.upsertUserOrganizations(supportedSystem,email,orgs, token).then(r=>{
      dispatch({
        type: types.DASHBOARD__UPSERAT_USER_ORGANIZATIONS_FULFILLED,
        payload: orgs,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
      callback(true);

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD__UPSERAT_USER_ORGANIZATIONS_REJECTED,
        payload: e.response,
      });
      callback(false);
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}
export function getPendingInvitationById(id){
  return function(dispatch){
    dispatch({ type: types.FLIGHT_STARTED });
    courseAssignationServices.getPendingInvitationById(id).then(r=>{
      dispatch({
        type: types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_FULFILLED,
        payload: r,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_REJECTED,
        payload: e.response,
      });
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function acceptInvitation(idSupportedSystem, idInvitation, callback){
  return function(dispatch){
    authenticationServices.acceptInvitation(idSupportedSystem, idInvitation).then(r=>{
      dispatch({
        type: types.DASHBOARD_ACCEPT_INVITATION_FULFILLED,
        payload: r,
      });
      callback(r);
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_ACCEPT_INVITATION_REJECTED,
        payload: e.response,
      });
      callback(false);
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}

export function saveOrganizationHierarchy(idOrganization,idLeader,  associates, callback){
  return function(dispatch){
    const token = localStorage.getItem("token");
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices.upsertEmployeesAssignation(idOrganization,idLeader, associates,token).then(r=>{
      dispatch({
        type: types.DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_FULFILLED,
        payload: r,
      });
      callback(r);
      dispatch({ type: types.FLIGHT_ARRIVED });

    }).catch(e=>{
      dispatch({
        type: types.DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_REJECTED,
        payload: e.response,
      });
      callback(false)
      dispatch({ type: types.FLIGHT_ARRIVED });
    })
  }
}