import { Modal } from "react-bootstrap";
import VideoPlayer from "../course-video-player-components/VideoPlayer";

//---------------------Translation
import Translate from "translate-components";

interface ModalVideoPreviewProps {
  show: boolean;
  name: string | undefined;
  description: string | undefined;
  url: string | undefined;
  onClose: () => void;
}

const ModalVideoPreview = ({
  show,
  name,
  description,
  url,
  onClose,
}: ModalVideoPreviewProps) => {
  const playerKey = url;

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Body className="padding-all-0">
        <div>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
          <VideoPlayer
            key={playerKey}
            videoSource={url ? url : ""}
            mustCompleteCourse={false}
          ></VideoPlayer>
        </div>
      </Modal.Body>
      <Modal.Body>
        <h2>
          <Translate>{name}</Translate>
        </h2>
        <p>
          <Translate>{description}</Translate>
        </p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <a className="btn btn-flat-papergray text-warning" onClick={onClose}>
            <Translate>close</Translate>
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVideoPreview;
