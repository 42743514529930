import React, { useEffect } from "react";
import * as actions from "../../data/Dashboard/dashBoardActions";
import {RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import SmartSelectComponent from "./Components/SmartSelectComponent";
import Translate from "translate-components";
const NewUser = () => {
  
  const [userName, setUserName] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [selectedOrganization, setSelectedOrganization] = React.useState();
  const [orgsToAdd, setOrgsToAdd] = React.useState<any>([]);

  const [isSending, setIsSending] = React.useState(false);
  
  const [isEmailFormatValid, setIsEmailFormatValid] = React.useState(false);
  const isEmailUnique= useSelector((state:RootStateOrAny)=>state.dashboard.isEmailUnique);
  const emailCheckChanged= useSelector((state:RootStateOrAny)=>state.dashboard.emailCheckChanged);

  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const organizations = useSelector( (state: RootStateOrAny) => state.dashboard.organizations);
  const newUserInvitations = useSelector( (state: RootStateOrAny) => state.dashboard.newUserInvitations);
  const organizationsEmployee = useSelector( (state: RootStateOrAny) => state.dashboard.organizationsEmployee);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  
  const validateEmailFormat = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const onEmailChange = (email: string) => {

    setIsEmailValid(false)
    setUserEmail(email);
    const isEmailValid =(validateEmailFormat(userEmail));
    if(isEmailValid)
      dispatch(actions.verifyEmailExist(email, token));
  }

  const onchangeOrganization = o => {
    setSelectedOrganization(o.target.value)
  }

  const sendEmail = () => {
    sendNewUserInvitation();
  }

  const deleteInvitation = (id) => {

    dispatch(actions.deletePendingInvitationAsync(id,(r)=>{
      if(r)
        toast.success("Invitation Deleted");
      else
        toast.error("Error deleting invitation");
    }));

  }

  useEffect(() => {
    
    dispatch(actions.getOrganizationByAssigner(userSession.userId, process.env.REACT_APP_SUPPORTED_SYSTEM));
    dispatch(actions.getNewUserInvitationsBySupportedSystemId(process.env.REACT_APP_SUPPORTED_SYSTEM));

  },[]);

  useEffect(() => {

    const isEmailValid =(validateEmailFormat(userEmail));
    setIsEmailFormatValid(isEmailValid);
    setIsEmailValid( isEmailValid);

  },[emailCheckChanged]);


  useEffect(() => {
    if(isEmailUnique && userEmail != null && userEmail != undefined && userEmail != ""){
      //Todo dispath the action to get the joined organizations of the user
      dispatch(actions.getOrganizationsJoinedByEmployee(userEmail, process.env.REACT_APP_SUPPORTED_SYSTEM));
    }
  },[isEmailUnique,userEmail])

  const formatDate =(date: string)=>{
    function isValidDate(dateString) {
      const dateNew = new Date(dateString);
      return dateNew instanceof Date;
  }
    var workingDate = new Date();
    if(isValidDate(date) && date != null && date != undefined)
      workingDate = new Date(date);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(workingDate);
  }

  const onOrganizationAdd=()=>{
    const alreadyExist = orgsToAdd.find(o=>o.id == selectedOrganization);
    if(alreadyExist)
      return;
    
    if(organizationsEmployee != null && organizationsEmployee.length > 0 && organizationsEmployee.find(o=>o.id == selectedOrganization))
      return;

    const orgToAdd = organizations.find(o=>o.id == selectedOrganization);
    if(orgToAdd)
      setOrgsToAdd([...orgsToAdd, orgToAdd]);

  }

  const removeOrgToAdd = (id) => {

    setOrgsToAdd(orgsToAdd.filter(o=>o.id != id));

  }

  const sendNewUserInvitation = () => {
    if(!isEmailUnique){
      dispatch(actions.sendNewUserInvitation(userSession.userId, process.env.REACT_APP_SUPPORTED_SYSTEM, userName, userLastName, userEmail, orgsToAdd, token, (r)=>{
        if(r){
          setIsEmailValid(false);
          setUserName("");
          setUserLastName("");
          setUserEmail("");
          setOrgsToAdd([]);
          toast.success("New User Invitation Sent");
          
        }else{
          toast.error("Error sending invitation");
        }
        setIsSending(false)
      }));
      setIsSending(true);
    }else{
      dispatch(actions.upsertUserOrganizations( process.env.REACT_APP_SUPPORTED_SYSTEM, userEmail, orgsToAdd, token, (r)=>{
        if(r){
          setIsEmailValid(false);
          setUserName("");
          setUserLastName("");
          setUserEmail("");
          setOrgsToAdd([]);
          toast.success("Organization joined");
          
        }else{
          toast.error("Error joined the organization");
        }
        setIsSending(false)
      }));
      setIsSending(true);
    }
    
  }


  const toggleAdmin = (index) => {

    const updatedOrgs = [...orgsToAdd]; // Create a copy of the array
    updatedOrgs[index].isAdmin = !updatedOrgs[index].isAdmin; // Toggle the isAdmin value
    setOrgsToAdd(updatedOrgs); // Update state

  };

  return (
    <>
    <div className="container py-4">
      <div className="row">
        <div className="col-12">
          <h1 className="dash my-2"><Translate>Admin_New_User</Translate></h1>
          <hr />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <label>
              <Translate>firstName</Translate>
            </label>
            <input placeholder="First Name" value={userName} onChange={v=>setUserName(v.target.value)}/>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <label>
              <Translate>lastName</Translate>
            </label>
            <input placeholder="Last Name" value={userLastName} onChange={v=>setUserLastName(v.target.value)} />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <div className="d-flex flex-column">
            <label>
              <Translate>email</Translate>
            </label>
            <input placeholder="Email" value={userEmail} onChange={v=>setUserEmail(v.target.value)} onBlur={v=>onEmailChange(v.target.value)} />
          </div>
        </div>
      </div>
      
      <div className="row border-b-lightgray my-4"> </div>

      <div className="row mb-4">
        <div className="col-12">
          <h1 className="dash smaller"><Translate>Organization</Translate></h1>
          <div className="d-flex">
            <SmartSelectComponent list={organizations} display={<Translate>Select_An_Organization</Translate>} onChange={onchangeOrganization}/>
            
            <button className="btn btn-flat-secondary left-10" onClick={onOrganizationAdd}>
              <Translate>Add</Translate>
            </button>
          </div>
        </div>
      </div>

      {/* Organizations */}
      <div className="row border-b-lightgray align-items-baseline ">
        {isEmailUnique && organizationsEmployee !=null  && organizationsEmployee.length >0 && organizationsEmployee.map((o,i)=>{
          return <div className="col-12 col-md-6 p-2" key ={"orgJoined" + i}>
              <div className="d-flex bkg-papergray p-2 rounded-2 justify-content-between align-items-center">
                <div className="w-50">
                {o.name}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className=" px-4 border-end border-start border-secondary">
                      <label htmlFor="isAdmin" className="right-5"> 
                      <Translate>IsAdmin</Translate>
                    </label>
                    <input id="isAdmin" type="checkbox" className=" h-auto" checked = {o.isAdmin || false} />
                  </div>
                </div>
              </div>
            </div>
        })
        }
        {orgsToAdd.map((o,i)=>{
          return <div className="col-12 col-md-6 p-2" key ={"orgAdded" + i}>
            <div className="d-flex bkg-papergray p-2 rounded-2 justify-content-between align-items-center">
              <div className="w-50">
               {o.name}
              </div>
  
              <div className="d-flex justify-content-between align-items-center">
                <div className=" px-4 border-end border-start border-secondary">
                    <label htmlFor="isAdmin1" className="right-5"> 
                    <Translate>IsAdmin</Translate>
                  </label>
                  <input id="isAdmin1" type="checkbox" className=" h-auto" checked = {o.isAdmin || false} onChange={() => toggleAdmin(i)}/>
                </div>
                <button className=" btn btn-sm btn-flat-papergray " onClick={()=>removeOrgToAdd(o.id)}>
                  <i className="material-icons text-warning">close</i>
                </button>
              </div>
            </div>
          </div>
        })}
      </div>
      
      <div className="row mt-2">
        <div className="col-12 d-flex">
          <button className={!isSending && isEmailValid && orgsToAdd.length > 0 ? "btn btn-flat-secondary d-flex right-20":"btn btn-flat d-flex right-20 disabled"}  onClick={()=>!isSending && isEmailValid &&  orgsToAdd.length && sendEmail()}>
            {!isEmailUnique &&
            <>
            <span className="right-6">
              {isSending?<Translate>Please_Wait</Translate>:<Translate>Send</Translate> }
            </span>
            <i className="material-icons text-background">send</i>
            </>
          }
          {isEmailUnique &&
            <>
            <span className="right-6">
              {isSending?<Translate>Please_Wait</Translate>:<Translate>Join</Translate> }
            </span>
            <i className="material-icons text-background">send</i>
            </>
          }
          </button>

        </div>
        {!isEmailValid && <div className="col-12 d-flex">
          <Translate>Admin_validate_email</Translate>
        </div>}
      </div>

      <div className="row border-b-lightgray my-4"> </div>

     {!isEmailUnique && <div className="row mb-4">
        <div className="col-12">
          <h1 className="dash smaller"><Translate>Pending_Invitations</Translate></h1>
          <div className="row">
          
          {newUserInvitations.map((ni,i)=><div className="col-12 col-md-6 p-2">
              <div className="d-flex bkg-papergray rounded-2 p-2 justify-content-between align-items-baseline flex-wrap flex-md-nowrap">
                <div className="w-50">
                  {ni.firstName} {ni.lastName}
                </div>
                
                <div className="d-flex align-items-baseline justify-content-between ">
                  <div>
                    <span className="text-accent4 small"><Translate>Admin_SetOn</Translate> </span> 
                    <span className="fw-medium">
                      {formatDate(ni.invitationSentAt)}
                    </span>
                  </div>
                  <button className="btn btn-sm btn-flat-background left-6 text-primary text-warning" onClick={()=>deleteInvitation(ni.id)}>
                    <Translate>Delete</Translate>
                  </button>
                </div>
                
              </div>
            </div>
          )}
            
          </div>
        </div>
      </div>}


    </div>
    </>
  );
};

export default NewUser;
