import ReactPlayer from "react-player";

type AudioPlayerProps = {
  mustCompleteCourse: boolean;
  completeCourse?: () => void;
  videoSource: string;
  playerKey?: string;
};

const AudioPlayer = ({
  mustCompleteCourse,
  completeCourse,
  videoSource,
  playerKey,
}: AudioPlayerProps) => {
  return (
    <>
      <div className="video">
        <ReactPlayer
          key={playerKey ?? `${Date.now()}-${Math.random()}`}
          controls={true}
          className="react-player"
          width="640"
          height="360"
          onEnded={mustCompleteCourse ? completeCourse : undefined}
          url={videoSource}
          playing
        />
      </div>
    </>
  );
};

export default AudioPlayer;
