import Translate from "translate-components";

//Return the course type icon of the course prerequisites
export const PrerequisitesTextFunc = (course) => {
    if (typeof course == "undefined" || course === null) return "";
    var type = "";
    var icon = "";
    switch (course.fkInstituteCourseType) {
      case 1:
        type = "course_type_video";
        icon = "ondemand_video";
        break;
      case 2:
        type = "course_type_reading";
        icon = "menu_book";
        break;
      case 3:
        type = "course_type_quiz";
        icon = "fact_check";
        break;
      case 4:
        type = "course_type_discution";
        icon = "supervised_user_circle";
        break;
      case 5:
        type = "course_type_donwload_material";
        icon = "download";
        break;
    case 6:
        type = "course_Information";
        icon = "info";
        break;  
    case 7:
        type = "course_link";
        icon = "link";
        break;          
    }
    return (
      <>
        <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
        {"  "}
        <span>{course?.name}</span>
      </>
    );
  };